<script setup lang="ts">
const social = ref([
  {
    name: 'Facebook',
    href: 'https://en-gb.facebook.com/butlersherborn/',
    icon: 'mdi:facebook',
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/butlersherborn/',
    icon: 'mdi:instagram',
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/butler-sherborn',
    icon: 'mdi:linkedin',
  },
])

const propertyMenu = [
  {
    name: 'Property for Sale',
    href: '/search',
  },
  {
    name: 'Property for Rent',
    href: '/search',
  },
  {
    name: 'Equestrian Property',
    href: '/rural/equestrian-property',
  },
  {
    name: 'Land & Farms',
    href: '/rural/land-sales-acquisitions',
  },
  {
    name: 'Market Your Property',
    href: '/property-services/sell',
  },
]

const infoMenu = [
  {
    name: 'About',
    href: '/about',
  },
  {
    name: 'Success Stories',
    href: '/success-stories',
  },
  {
    name: 'News & Comment',
    href: '/articles',
  },
  {
    name: 'Careers',
    href: '/careers',
  },
  {
    name: 'Policies & Procedures',
    href: '/policies',
  },
  {
    name: 'Fees & Charges',
    href: '/fees',
  },
  {
    name: 'Contact Us',
    href: '/contact',
  },
]
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-10 lg:gap-12 mt-12 mb-14">
    <!-- Logo & social -->
    <div class="space-y-8 lg:space-y-12 md:col-span-10 lg:col-span-3 mb-8">
      <div class="space-y-4">
        <LogoMain
          mode="normal"
          :shrink="false"
        />
        <div class="text-base text-forest-50">
          The Cotswolds Property Specialist
        </div>
      </div>
      <div class="flex space-x-6">
        <nuxt-link
          v-for="item in social"
          :key="item.name"
          :href="item.href"
          class="block text-forest-50 hover:text-white"
        >
          <span class="sr-only">{{ item.name }}</span>
          <Icon :name="item.icon" class="h-7 w-7" aria-hidden="true" />
        </nuxt-link>
      </div>
    </div>

    <!-- Cotswold Property -->
    <div class="space-y-3 lg:space-y-6 md:col-span-3 lg:col-span-2 mb-8 lg:mb-0">
      <h3 class="text-lg lg:text-xl font-bold text-gray-50">
        Cotswold Property
      </h3>
      <div class="space-y-2 lg:space-y-3 text-sm lg:text-base">
        <nuxt-link
          v-for="item in propertyMenu"
          :key="item.name"
          :href="item.href"
          class="block text-forest-50 hover:text-white"
        >
          {{ item.name }}
        </nuxt-link>
      </div>
    </div>
    <!-- Info Menu -->
    <div class="space-y-3 lg:space-y-6 md:col-span-3 lg:col-span-2 mb-8 lg:mb-0">
      <h3 class="text-lg lg:text-xl font-bold text-gray-50">
        Information
      </h3>
      <div class="space-y-2 lg:space-y-3 text-sm lg:text-base">
        <nuxt-link
          v-for="item in infoMenu"
          :key="item.name"
          :href="item.href"
          class="block text-forest-50 hover:text-white"
        >
          {{ item.name }}
        </nuxt-link>
      </div>
    </div>
    <!-- Our Offices -->
    <div class="space-y-3 lg:space-y-6 md:col-span-3">
      <h3 class="text-lg lg:text-xl font-bold text-gray-50">
        Offices
      </h3>
      <div class="text-sm lg:text-base">
        <FooterBranches />
      </div>
    </div>
  </div>
</template>
